<template>
    <div class="main-hot">
        <!-- 标题 -->
        <div class="main-hot-title">
            <span>{{hotTitle}}</span>
        </div>
        <!-- more -->
        <div class="main-hot-more">
            <router-link :to="listRouter">
                查看更多热门
                <i class="el-icon-d-arrow-right"></i>
            </router-link>
        </div>
        <!-- 列表 -->
        <ul class="main-hot-list">
            <li v-for="(item, index) in goodsList"
                :key="index">
                <slot name="goodsItem"
                    :item="item">
                </slot>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            hotTitle: String,
            listRouter: String,
            goodsList: Array
        },
    }
</script>

<style scoped>
.main-hot{
    margin-top: 100px;
}
/* 标题 */
.main-hot-title{
    text-align: center;
}
.main-hot-title > span{
    display: inline-block;
    padding: 30px 20px;
    letter-spacing: 6px;
    font-size: 26px;
}
/* more */
.main-hot-more{
    margin-bottom: 20px;
    text-align: right;
}
.main-hot-more > a{
    color: var(--color);
}
/* list */
.main-hot-list > li{
    float: left;
    width: 25%;
    padding: 10px;
}
</style>